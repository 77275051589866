<script lang="ts" setup>

</script>

<template>
  <!-- main -->
  <main class="dataNone">
    <div>
      <div class="img">
        <img src="~/assets/images/sub/img_no_data_2.svg" alt="" />
      </div>
      <h3>화면을 불러오지 못했어요</h3>
      <p>해당 페이지에 접근할 수 없어요</p>
    </div>
  </main>
  <!-- //main -->
</template>
